<template>
  <div class="page vacancies-page">
    <BreadcrumbsComponent title="Вакансии" />
    <div class="page__inner">
      <SectionComponent>
        <div class="vacancies-page__inner">
          <VacanciesComponent :vacancies="vacancies" />
        </div>
      </SectionComponent>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import VacanciesComponent from "./components/VacanciesComponent.vue";
import VACANCIES_PAGE from "gql/pages/VacanciesPage.graphql";

export default {
  name: "VacanciesPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: VACANCIES_PAGE,
        variables: {},
      })
      .then(({ data }) => {
        store.state.vacancies_page.vacancies = data.vacancies || [];
        // дефолтные данные
        store.state.global.categories = data.categories || [];
        store.state.global.feedback = data.feedback || {};
        store.state.global.pages = data.pages || [];
      })
      .catch(() => {});
  },
  computed: {
    vacancies() {
      return this.$store.state.vacancies_page.vacancies;
    },
  },
  components: { VacanciesComponent, SectionComponent, BreadcrumbsComponent },
  metaInfo: {
    title: "Вакансии",
  },
};
</script>

<style lang="stylus">
.vacancies-page {
  &__inner {
    display flex
    gap 30px
    +below(800px) {
      flex-direction column
    }
  }
}
</style>
