<template>
  <div v-if="vacancies && vacancies.length" class="vacancies">
    <div
      v-for="(vacancy, i) in vacancies"
      :key="i"
      class="vacancy-item"
      :class="{ 'vacancy-item__open': activeVacancy === vacancy }"
    >
      <a type="button" class="vacancy-item__header no-hover" @click="toggleVacancy(vacancy)">
        <!--        <div class="vacancy-item__header-text">-->
        <span class="vacancy-item__title">{{ vacancy.title }}</span>
        <div class="vacancy-item__subtitle">
          Зарплата:
          <span class="vacancy-item__subtitle--salary"> от {{ vacancy.salary | formatPrice }}</span>
        </div>
        <!--        </div>-->
        <IconComponent name="arrow-up" />
      </a>
      <div class="vacancy-item__body" v-if="activeVacancy === vacancy">
        <div class="vacancy-item__body-title">
          Опыт работы: <span class="vacancy-item__body-title--experience">{{ vacancy.experience }}</span>
        </div>
        <span class="vacancy-item__body-title">Описание:</span>
        <span class="vacancy-item__body-description">{{ vacancy.description }}</span>
        <button @click="openVacancyModal(vacancy)" class="btn btn--lg btn--main vacancy-item__body-btn">
          Откликнуться на вакансию
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import VacancyModal from "components/modals/components/VacancyModal.vue";

export default {
  name: "vacanciesComponent",
  components: { IconComponent },
  props: {
    vacancies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeVacancy: null,
    };
  },
  methods: {
    toggleVacancy(vacancy) {
      this.activeVacancy = this.activeVacancy === vacancy ? null : vacancy;
    },
    openVacancyModal(vacancy) {
      this.$store.state._modals.push({
        component: VacancyModal,
        options: { vacancy: vacancy },
      });
    },
  },
};
</script>

<style lang="stylus">
.vacancies {
  width 100%
  display flex
  flex-direction column
  gap 20px
}

.vacancy-item {
  max-width 857px
  display grid
  align-items start
  border-radius var(--big-radius)
  padding 30px
  grid-gap 20px
  background var(--gray)
  +below(473px) {
    padding 20px
  }

  &__open &__header {
    .icon {
      transform rotate(180deg)
    }
  }

  &__header {
    display grid
    grid-template-columns: 380fr 380fr 16fr;
    align-items center
    justify-content space-between
    text-align left
    gap 20px
    border none
    background none
    cursor pointer

    &-text {
      display flex
      flex-wrap wrap
      align-items center
      gap: 300px
      +below(800px){
        gap 30px
      }
    }

    .icon {
      width 16px
      height 16px
    }

    &:hover {
      color var(--main)
      transition var(--transition)

      .icon svg {
        path {
          stroke var(--main)
          transition var(--transition)
        }
      }
    }
  }

  &__title {
    font-size 1.375em
    font-weight 700
    line-height 26px
    +below(473px) {
      font-size 1.125em
    }
  }

  &__subtitle {
    font-size 1.125em
    font-weight 700
    line-height 26px

    &--salary {
      line-height 28px
      font-weight 400
    }
  }

  &__body {
    display flex
    flex-direction column
    gap 10px
  }

  &__body-title {
    display flex
    gap 5px
    font-size 1.125em
    font-weight 700
    line-height 26px

    &--experience {
      font-weight 400
      line-height 28px
    }
  }

  &__body-description {
    font-size 1.125em
    font-weight 400
    line-height 28px
  }

  &__body-btn {
    max-width 263px
    margin-top 10px
    +below(440px) {
      max-width 100%
      width 100%
    }
  }
}
</style>
