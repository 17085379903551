<template>
  <div
    class="input input--file"
    :class="{
      'input--file--disabled': disabled,
      'input--file--highlight': highlight && !disabled,
      'input--file--loading': loading,
    }"
  >
    <span class="input__title" v-if="title">
      <span>{{ title }}</span>
      <span class="input__required" v-if="required">*</span>
    </span>
    <label
      class="input__container"
      @dragenter="dragOver"
      @dragleave="dragLeave"
      @dragover="dragOver"
      @drop="drop"
    >
      <input
        v-show="false"
        :accept="accept"
        ref="input"
        :disabled="loading || disabled"
        type="file"
        @change="change"
      />
      <span class="input--file-content">
        <span class="input--file-placeholder">{{ files ? files.origin_name : placeholder }}</span>
        <IconComponent v-if="loading" name="loading" class="input--file-loading" />
        <span v-else class="input--file-tag">Выбрать файл</span>
      </span>
    </label>
  </div>
</template>

<script>
import FILES_UPLOAD from "@/graphql/mutations/FilesUpload.graphql";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "FileUploadComponent",
  components: { IconComponent },
  props: {
    uploaded: Object,
    disabled: Boolean,
    title: String,
    placeholder: String,
    accept: {
      type: String,
      default: "image/*",
    },
    required: Boolean,
  },
  data() {
    return {
      drag: false,
      highlight: false,
      over: false,
      loading: false,
      files: null,
      dragTimeout: null,
      highlightTimeout: null,
      image: null,
      regexp: {
        image: /image\/*/,
        video: /video\/*/,
      },
    };
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.files = this.uploaded;
      }
    },
  },
  mounted() {
    if (this.uploaded) {
      this.files = this.uploaded;
    }
    document.addEventListener("dragover", (event) => {
      event.preventDefault();
      event.stopPropagation();
    });
    document.addEventListener("dragover", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.drag = true;
    });
    document.addEventListener("dragleave", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.debouncedDelayedSetOfDrag(false);
    });
  },
  methods: {
    clear() {
      if (confirm("Вы уверены?")) {
        this.$emit("change", null);
      }
    },
    debouncedDelayedSetOfDrag(value) {
      clearTimeout(this.dragTimeout);
      this.dragTimeout = setTimeout(() => {
        this.drag = value;
      }, 200);
    },
    debouncedDelayedSetOfHighlight(value) {
      clearTimeout(this.highlightTimeout);
      this.highlightTimeout = setTimeout(() => {
        this.highlight = value;
      }, 200);
    },
    dragLeave(event) {
      event.preventDefault();
      event.stopPropagation();
      this.debouncedDelayedSetOfHighlight(false);
    },
    dragOver(event) {
      event.preventDefault();
      event.stopPropagation();
      this.highlight = true;
    },
    drop(event) {
      event.preventDefault();
      const dt = event.dataTransfer;
      if (dt) {
        this.loading = true;
        this.debouncedDelayedSetOfHighlight(false);
        this.debouncedDelayedSetOfDrag(false);
        this.upload(dt.files);
      }
    },
    change(event) {
      if (!this.loading && !this.disabled) {
        this.loading = true;
        if (event.target.files.length) {
          this.upload(event.target.files);
        }
      }
    },
    upload(files) {
      if (files && files.length) {
        this.$apollo
          .mutate({
            mutation: FILES_UPLOAD,
            variables: {
              files: files,
            },
          })
          .then(({ data }) => {
            if (data.FilesUpload && data.FilesUpload.length) {
              this.files = data.FilesUpload[0];
            }
            this.loading = false;
            this.$emit("change", this.files);
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/elements/input.styl"
@import "~@/styles/mixins/maxlines.styl"
.input--file {
  cursor pointer

  &--highlight:not(&--loading):not(&--disabled)
  &:not(&--loading):not(&--disabled):hover {
    .input__container {
      border-color var(--main)
    }
  }

  &-content {
    width 100%
    display flex
    align-items center
    justify-content space-between
    gap: 10px
    padding 0 20px
  }

  &-placeholder {
    maxlines(1)
    color: var(--dark-light);
    opacity 0.5
  }

  &-loading {
    svg circle {
      stroke var(--black)
    }
  }

  &-tag {
    flex-shrink 0
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 28px;
    background: var(--dark-light);
    border-radius: 5px;
    font-weight: 400;
    font-size: 0.725em;
    line-height: 14px;
    color: var(--white);
  }
}
</style>
