<template>
  <div class="default-modal vacancy-modal">
    <button class="default-modal__close" @click="$emit('closeAll')">
      <IconComponent name="close" />
    </button>
    <div class="default-modal__header">
      <h2 class="default-modal__title">Отклик на вакансию</h2>
    </div>
    <div class="default-modal__body vacancy-modal__body">
      <div class="vacancy-modal__inputs">
        <InputComponent v-model="vacancy.title" placeholder="Специальность" disabled white />
        <InputComponent v-model="form.fio.value" :error="form.fio.error" placeholder="ФИО" white />
        <InputComponent
          v-model="form.phone.value"
          :error="form.phone.error"
          placeholder="Телефон"
          mask="+7 (###) ### - ## - ##"
          is-phone
          white
        />
        <InputComponent v-model="form.email.value" :error="form.email.error" placeholder="Email" white />
        <FileUploadComponent @change="getFiles" accept="application/pdf" placeholder="Резюме" />
      </div>
    </div>
    <div class="default-modal__footer vacancy-modal__footer">
      <button @click="claimCreate" class="btn btn--lg btn--main vacancy-modal__btn">
        <span>Отправить</span>
        <IconComponent name="loading" v-if="loading" />
      </button>
      <div class="vacancy-modal__footer-title">
        Нажимая на кнопку, вы соглашаетесь на сбор и обработку
        <router-link
          :to="{ name: 'document', params: { id: $store.state._types.PERSONAL_DATA } }"
          class="vacancy-modal__footer-title--underline"
        >
          персональных данных
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import FileUploadComponent from "components/inputs/FileUploadComponent.vue";
import VACANCY_CLAIM_CREATE from "gql/mutations/VacancyClaimCreate.graphql";
import { parseGqlErrors } from "@/utils";
import { resetErrors } from "../../../utils";

export default {
  name: "VacancyModal",
  props: {
    vacancy: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      number: null,
      loading: false,
      error: null,
      codeError: null,
      form: {
        fio: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        phone: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        resume: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
      },
    };
  },
  methods: {
    parseGqlErrors,
    resetErrors,
    getFiles(file) {
      if (file) {
        this.form.resume.value = file;
        delete this.form.resume.value.__typename;
      }
    },
    claimCreate() {
      if (!this.loading) {
        this.loading = true;
        this.resetErrors("form");
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: VACANCY_CLAIM_CREATE,
            variables: {
              vacancy: this.vacancy.id,
              fio: this.form.fio.value,
              phone: this.form.phone.value ? this.form.phone.value.replace(/[^\d+]/g, "") : undefined,
              email: this.form.email.value,
              file: this.form.resume.value,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            this.loading = false;
            if (data.VacancyClaimCreate) {
              this.$notify({
                title: "Успешно",
                type: "success",
              });
              this.$store.state._modals = [];
            }
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
  },
  components: { IconComponent, InputComponent, FileUploadComponent },
};
</script>

<style lang="stylus">
.vacancy-modal {
  width 100%
  max-width 600px
  padding 30px

  &__inputs {
    width 100%
    display grid
    grid-gap 20px

    .input {
      width 100%
    }
  }

  &__footer {
    display flex
    flex-direction row
    gap 20px
    +below(520px) {
      flex-direction column
    }
  }

  &__footer-title {
    font-size 0.875em
    line-height 20px
    color var(--dark-light)

    &--underline {
      text-decoration underline
    }
  }

  &__body {
    padding-top 10px
  }

  &__body > .input {
    width 100%
  }

  &__footer .btn {
    flex-shrink 0
    width auto
    justify-content center
    +below(520px) {
      width 100%
    }
  }
}
</style>
